import React from "react"
import { CenterAligned, FlexColumn, Media, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { Link, LinkProps } from "@/components/common/Link"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { PrimaryDropHeroMedia } from "@/features/primary-drops/components/PrimaryDropHeroMedia"
import { ResponsiveText } from "@/features/primary-drops/components/ResponsiveText"
import {
  blurClearButton,
  linkParentCss,
  nonInteractiveOverlay,
} from "@/features/primary-drops/styleUtils"
import { useTranslate } from "@/hooks/useTranslate"
import { CategorySpotlightBanner_data$key } from "@/lib/graphql/__generated__/CategorySpotlightBanner_data.graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { display } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"

type CategorySpotlightBannerProps = {
  dataKey: CategorySpotlightBanner_data$key | null
  disableZoomOnHover?: boolean
  overrideUrl?: string | null
} & Pick<LinkProps, "eventParams" | "eventSource">

export const CategorySpotlightBanner = ({
  dataKey,
  disableZoomOnHover,
  eventParams,
  eventSource,
  overrideUrl,
}: CategorySpotlightBannerProps) => {
  const t = useTranslate("home")
  const data = useFragment<CategorySpotlightBanner_data$key>(
    graphql`
      fragment CategorySpotlightBanner_data on CollectionType {
        ...collection_url
        assetCount
        name
        bannerImageUrl
        logo
        verificationStatus
        owner {
          config
          displayName
          ...AccountLink_data
        }
        statsV2 {
          floorPrice {
            unit
            symbol
          }
        }
        metadata {
          hero {
            ...PrimaryDropHeroMedia_data
          }
        }
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  const { verificationStatus, owner, metadata } = data

  return (
    <Content disableZoomOnHover={!!disableZoomOnHover}>
      <SpotlightCard>
        <MediaContainer>
          {metadata?.hero ? (
            <PrimaryDropHeroMedia
              dataKey={metadata.hero}
              title={t("spotlight.viewDrop.title", "home page spotlight")}
            />
          ) : (
            <>
              <Media lessThan="md">
                <SpotlightCardImage
                  src={overrideUrl || data.bannerImageUrl || ""}
                  width={1200}
                />
              </Media>
              <Media greaterThanOrEqual="md">
                <SpotlightCardImage
                  src={overrideUrl || data.bannerImageUrl || ""}
                  width={2560}
                />
              </Media>
            </>
          )}
        </MediaContainer>
        <OpacityLayer />
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <Link
          className="absolute h-full w-full"
          eventParams={eventParams}
          eventSource={eventSource}
          href={getCollectionUrl(data)}
        />
        <FlexColumn className="pointer-events-none z-[3] w-full flex-1 justify-end xs:p-4 md:p-10">
          {data.logo && (
            <LogoContainer>
              <Image
                alt={owner?.displayName ?? ""}
                layout="fill"
                objectFit="contain"
                objectPosition="left"
                priority
                src={data.logo}
                width={280}
              />
            </LogoContainer>
          )}

          <Flex justifyContent="space-between" marginTop={24}>
            <FlexColumn className="w-[70%]">
              <HeadingContainer>
                <Heading size="medium">
                  {data.name}
                  {verificationStatus === "VERIFIED" && (
                    <span>
                      <StyledCollectionVerificationIcon />
                    </span>
                  )}
                </Heading>
              </HeadingContainer>
              {owner && (
                <Flex alignItems="center" marginTop="4px">
                  <OwnerText size="medium" weight="semibold">
                    By&nbsp;
                    <AccountLink
                      dataKey={owner}
                      showBadge={false}
                      variant="no-image"
                    />
                  </OwnerText>
                  {owner.config === "VERIFIED" && (
                    <CenterAligned className="ml-1">
                      <StyledAccountVerificationIcon />
                    </CenterAligned>
                  )}
                </Flex>
              )}

              <Flex marginTop="4px">
                {!!data.assetCount && (
                  <Text.Body
                    className="text-white"
                    size="medium"
                    weight="semibold"
                  >
                    {t(
                      "spotlight.collectionStats.items",
                      "{{itemCount}} {{itemLabel}}",
                      {
                        itemLabel: t(
                          "spotlight.collectionStats.itemsCount",
                          {
                            "0": "items",
                            one: "item",
                            other: "items",
                          },
                          {
                            count: data.assetCount,
                          },
                          { forceString: true },
                        ),
                        itemCount: <>{display(data.assetCount)}</>,
                      },
                    )}
                  </Text.Body>
                )}
                {data.statsV2.floorPrice && (
                  <Text.Body
                    className="text-white"
                    size="medium"
                    weight="semibold"
                  >
                    &nbsp;·&nbsp;
                    {t(
                      "spotlight.collectionStats.floor",
                      "{{unit}} {{symbol}}",
                      {
                        unit: data.statsV2.floorPrice.unit,
                        symbol: data.statsV2.floorPrice.symbol,
                      },
                    )}
                  </Text.Body>
                )}
              </Flex>
            </FlexColumn>
            <FlexColumn className="self-end">
              <ViewDrop>
                <ResponsiveText.Body
                  color="white"
                  size="medium"
                  weight="semibold"
                >
                  {t("spotlight.viewCollection.button", "View collection")}
                </ResponsiveText.Body>
              </ViewDrop>
            </FlexColumn>
          </Flex>
        </FlexColumn>
      </SpotlightCard>
    </Content>
  )
}

const MediaContainer = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  position: absolute;
`

const LogoContainer = styled(Block)`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: black;
  overflow: hidden;
  ${media({
    xs: css`
      height: 48px;
      width: 48px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
    md: css`
      height: 64px;
      width: 64px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
  })}
`

const StyledCollectionVerificationIcon = styled(VerifiedIcon).attrs({
  size: "tiny",
  variant: "white",
})`
  ${media({
    xs: css`
      height: 16px;
      width: 16px;
    `,
    md: css`
      height: 24px;
      width: 24px;
      /* Due to the alignment of the text, we need to offset this icon a bit to visually center it */
      position: relative;
      top: 2px;
    `,
  })}
`

const StyledAccountVerificationIcon = styled(VerifiedIcon).attrs({
  size: "tiny",
  variant: "white",
})`
  ${media({
    xs: css`
      height: 12px;
      width: 12px;
    `,
    md: css`
      height: 14px;
      width: 14px;
    `,
  })}
`

const OpacityLayer = styled(Block)`
  ${nonInteractiveOverlay}
  background-color: rgba(0, 0, 0, 0.2);
`

const SetReminderButton = styled(Flex)`
  pointer-events: all;
  ${blurClearButton}
  width: var(--button-height);
  ${media({
    md: css`
      margin-right: 10px;
    `,
  })}
`

const ViewDrop = styled(Flex)`
  min-height: 100%;
  height: var(--button-height);
  ${blurClearButton}
  ${media({
    xs: css`
      display: none;
    `,
    md: css`
      display: flex;
    `,
  })}
`

const SpotlightCard = styled(Flex)<{
  $bgImage?: string
}>`
  height: 100%;
  width: 100%;
  &:after {
    ${nonInteractiveOverlay}
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.24) 100%
    );
  }
  background: url(${({ $bgImage }) => $bgImage}) center no-repeat;
  background-size: cover;
  overflow: hidden;
`

const zoomAnimationCss = css`
  ${MediaContainer} {
    img,
    video {
      transition: transform 0.2s 0s ease-in-out;
    }
  }
  &:hover {
    ${MediaContainer} {
      img {
        transform: scale(1.03);
      }
      video {
        transform: scale(1.03) translate(-49%, -49%);
      }
    }
  }
  &:active {
    ${MediaContainer} {
      video {
        transform: scale(1.04) translate(-49%, -49%);
      }
      img {
        transform: scale(1.04);
      }
    }
  }
`

const viewDropAnimationCss = css`
  ${ViewDrop} {
    transition: background-color 0.2s 0s ease-in-out;
  }
  &:hover {
    ${ViewDrop} {
      background-color: white;
      i {
        color: black;
      }
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
  }
  &:active {
    ${ViewDrop} {
      background-color: rgba(255, 255, 255, 0.9);
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
  }
`

const setReminderAnimationCss = css`
  ${SetReminderButton} {
    transition: background-color 0.2s 0s ease-in-out;
    &:hover {
      background-color: white;
      i {
        color: black;
      }
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.9);
      span {
        color: ${props => props.theme.colors.charcoal};
      }
    }
  }
`

const Content = styled(FlexColumn)<{ disableZoomOnHover: boolean }>`
  /*
    height = ratio * width
    width = screenWidth - (paddingLeft + paddingRight)
    so, height = ratio * (screenWidth - (paddingLeft + paddingRight))
  */
  cursor: pointer;
  ${props => (!props.disableZoomOnHover ? zoomAnimationCss : "")}
  ${viewDropAnimationCss}
  ${setReminderAnimationCss}
  ${media({
    xs: css`
      border-radius: ${props => props.theme.borderRadius.large};
      /* ratio 7/8 (8/7 = 1.14) */
      height: calc(1.14 * (100vw - 32px));
    `,
    md: css`
      /* ratio 4/3 (3/4 = 0.75) */
      height: calc(0.75 * (100vw - 32px));
    `,
    lg: css`
      /* ratio 5/2 (2/5 = 0.4) */
      height: calc(0.4 * (100vw - 64px));
    `,
    xl: css`
      border-radius: ${props => props.theme.borderRadius.large};
    `,
    xxl: css`
      /* ratio 18/5 (5/18 = 0.278) */
      height: calc(0.278 * (100vw - 128px));
    `,
  })}
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 3;
`

const Heading = styled(ResponsiveText.Display)`
  && {
    color: white;
  }
  margin: 0;
  ${linkParentCss}

  span {
    display: inline-block;
    margin-left: 8px;
  }
`

const OwnerText = styled(ResponsiveText.Body)`
  align-items: center;
  margin: 0;
  && {
    color: white;
  }
  display: flex;
  ${linkParentCss};
`
const HeadingContainer = styled.h1`
  margin: 0;
  ${media({
    xs: css`
      width: 75%;
    `,
    lg: css`
      width: 66%;
    `,
  })}
`

const SpotlightCardImage = ({
  src,
  width,
}: {
  src: string | undefined
  width: number
}) => {
  if (!src) {
    return null
  }
  return (
    <Image
      alt=""
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      priority
      src={src}
      width={width}
    />
  )
}
