import React from "react"
import { Media, Text, Container, Flex } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCollectionCard } from "@/features/home/components/HomePageShelf/HomePageCollectionCard.react"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import { PromoCategorySectionQuery } from "@/lib/graphql/__generated__/PromoCategorySectionQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useCategoryNameGetter } from "./collection-edit/categoryUtils"
import { CategoryScrollerSkeleton } from "./TrendingCategory/CategoryScrollerSkeleton.react"

type Props = {
  category: string
}

export const PromoCategorySection = ({ category }: Props) => {
  const t = useTranslate("categorization")
  const categoryNameGetter = useCategoryNameGetter()
  const [data] = useNoSuspenseLazyLoadQuery<PromoCategorySectionQuery>(
    graphql`
      query PromoCategorySectionQuery($category: CategoryV2Slug!) {
        categoryPagePromotion(category: $category) {
          collection {
            id
            ...HomePageCollectionCard_data @arguments(timeWindow: SEVEN_DAY)
          }
        }
      }
    `,
    { category },
  )

  if (!data) {
    return (
      <Grid.Item xs={12}>
        <Container>
          <CategoryScrollerSkeleton />
        </Container>
      </Grid.Item>
    )
  }

  const slides = data.categoryPagePromotion.map((collection, idx) => {
    return {
      id: `${collection.collection.id}`,
      collection: (
        <HomePageCollectionCard
          collection={collection.collection}
          eventParams={{
            idx,
            category,
          }}
          eventSource="CategoryTagShelfLinkClick"
          volumeWindow="SEVEN_DAY"
        />
      ),
    }
  })

  if (!data.categoryPagePromotion.length) {
    return null
  }

  const sectionName = t("category.promo.header", "{{category}} spotlight", {
    category: categoryNameGetter(category),
  })

  return (
    <Grid.Item xs={12}>
      {/* Overflow hidden used for Carousel "card" variants that require a visible overflow */}
      <Container className="overflow-x-hidden">
        <Flex className="items-center pb-3 lg:pb-6">
          <Media greaterThanOrEqual="xxl">
            <Text.Heading size="large">{sectionName}</Text.Heading>
          </Media>
          <Media between={["lg", "xxl"]}>
            <Text.Heading size="medium">{sectionName}</Text.Heading>
          </Media>
          <Media lessThan="lg">
            <Text.Heading size="small">{sectionName}</Text.Heading>
          </Media>
        </Flex>

        <Block
          alignSelf="center"
          data-testid="Home--category-scroller"
          marginX={{ _: "-16px", sm: "0px" }}
        >
          <Media lessThan="sm">
            <Carousel
              centeredSlides
              centeredSlidesBounds
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={`${category}_category_promo_scroller_mobile`}
              enableArrowControls={false}
              enableFreeScroll
              enableMousewheel
              id={`category-promo-scroller-${category}-mobile`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              variant="card"
            />
          </Media>
          <Media greaterThanOrEqual="sm">
            <Carousel
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={`${category}_category_promo_scroller`}
              enableArrowControls
              enableFreeScroll
              enableMousewheel
              id={`category-promo-scroller-${category}`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              variant="card"
            />
          </Media>
        </Block>
      </Container>
    </Grid.Item>
  )
}
