/**
 * @generated SignedSource<<560dc6bd53bd7683449f101d9e494f0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type categoriesCollectionQuery$variables = {
  collection: string;
};
export type categoriesCollectionQuery$data = {
  readonly collection: {
    readonly id: string;
  } | null;
};
export type categoriesCollectionQuery = {
  response: categoriesCollectionQuery$data;
  variables: categoriesCollectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "collection",
        "variableName": "collection"
      }
    ],
    "concreteType": "CollectionType",
    "kind": "LinkedField",
    "name": "collection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "categoriesCollectionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "categoriesCollectionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a7b4133ec4e360816d17909e953b402c",
    "id": null,
    "metadata": {},
    "name": "categoriesCollectionQuery",
    "operationKind": "query",
    "text": "query categoriesCollectionQuery(\n  $collection: CollectionSlug!\n) {\n  collection(collection: $collection) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "56b10e2513e74c537e6556cd90a6101a";

export default node;
