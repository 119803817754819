
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { forceNextTranslateGetInitialProps } from "@/components/app/initialProps";
import { CategoryPage } from "@/features/category/CategoryPage.react";
const __Next_Translate__Page__195fd7ba2e4__ = forceNextTranslateGetInitialProps(CategoryPage);

    export default __appWithI18n(__Next_Translate__Page__195fd7ba2e4__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  