import { useMemo } from "react"
import { useUpdateEffect } from "react-use"
import { getTrackingFn } from "@/lib/analytics"
import { TopCategoryBuysSectionQuery } from "@/lib/graphql/__generated__/TopCategoryBuysSectionQuery.graphql"

type TrackNotableActivityCollectionsLoadedArgs = {
  category: string
  collectionsCount?: number
}

const trackNotableActivityCollectionsLoaded =
  getTrackingFn<TrackNotableActivityCollectionsLoadedArgs>(
    "notable collections loaded",
  )

export function useTrackNotableActivityCollectionsLoaded({
  category,
  collections,
}: {
  category: string
  collections?: TopCategoryBuysSectionQuery["response"] | null
}) {
  const idSignatures = useMemo(
    () =>
      collections?.notableActivityCollections.edges.map(
        edge => edge?.node?.id || "",
      ),
    [collections],
  )

  useUpdateEffect(() => {
    if (collections?.notableActivityCollections.edges) {
      trackNotableActivityCollectionsLoaded({
        category,
        collectionsCount:
          collections.notableActivityCollections.edges.length || 0,
      })
    }
  }, [idSignatures])
}
