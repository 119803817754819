import React, { useMemo } from "react"
import { Media, Text, Container, Flex } from "@opensea/ui-kit"
import { orderBy, times } from "lodash"
import { graphql } from "react-relay"
import { StyledGridHScrollContainer } from "@/components/common/StyledGridHScrollContainer"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCollectionCard } from "@/features/home/components/HomePageShelf/HomePageCollectionCard.react"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import {
  TrendingTagListQuery,
  TrendingTagListQuery$data,
} from "@/lib/graphql/__generated__/TrendingTagListQuery.graphql"
import { useTagsNameGetter } from "../collection-edit/categoryUtils"
import { CategoryScrollerSkeleton } from "./CategoryScrollerSkeleton.react"

type Props = {
  category: string
  tags: string[]
}

type TagsToCollections = Record<
  string,
  | NonNullable<
      NonNullable<
        TrendingTagListQuery$data["trendingCollectionsByCategoryTagBatched"]
      >[0]
    >["collection"][]
  | undefined
>

export const TrendingTagList = ({ category, tags }: Props) => {
  const t = useTranslate("home")
  const tagNameFn = useTagsNameGetter(
    category as Parameters<typeof useTagsNameGetter>[0],
  )

  const [data] = useNoSuspenseLazyLoadQuery<TrendingTagListQuery>(
    graphql`
      query TrendingTagListQuery(
        $categories: [CategoryV2Slug!]!
        $tags: [TagSlug!]!
      ) {
        trendingCollectionsByCategoryTagBatched(
          categories: $categories
          tags: $tags
        ) {
          collection {
            id
            ...HomePageCollectionCard_data @arguments(timeWindow: SEVEN_DAY)
          }
          tag {
            slug
          }
        }
      }
    `,
    { categories: [category], tags },
  )

  // group by tags
  const tagsToCollections = useMemo(
    () =>
      data?.trendingCollectionsByCategoryTagBatched?.reduce<TagsToCollections>(
        (acc, item) => {
          if (!item?.collection || !item.tag?.slug) {
            return acc
          }

          if (acc[item.tag.slug]) {
            acc[item.tag.slug]?.push(item.collection)

            return acc
          }

          return {
            ...acc,
            [item.tag.slug]: [item.collection],
          }
        },
        {},
      ) ?? {},
    [data?.trendingCollectionsByCategoryTagBatched],
  )

  if (!data?.trendingCollectionsByCategoryTagBatched) {
    return (
      <>
        {times(3, i => (
          <Grid.Item key={i} marginBottom="32px">
            <CategoryScrollerSkeleton />
          </Grid.Item>
        ))}
      </>
    )
  }

  const tagList = orderBy(Object.keys(tagsToCollections), tags).reverse()

  return (
    <StyledGridHScrollContainer>
      {tagList.map(tag => {
        const collections = tagsToCollections[tag]

        const slides = (collections ?? [])
          .map((collection, idx) => {
            if (!collection) {
              return null
            }

            return {
              id: `${collection.id}`,
              collection: (
                <HomePageCollectionCard
                  collection={collection}
                  eventParams={{
                    idx,
                    tag,
                    category,
                  }}
                  eventSource="CategoryTrendingTagShelfLinkClick"
                  volumeWindow="SEVEN_DAY"
                />
              ),
            }
          })
          .filter((s): s is { id: string; collection: JSX.Element } => !!s)

        const sectionName = t(
          "trendingCategoryList.title",
          "Trending in {{ categoryName }}",
          { categoryName: tagNameFn(tag) },
        )

        return (
          <Grid.Item key={tag}>
            {/* Overflow hidden used for Carousel "card" variants that require a visible overflow */}
            <Container className="overflow-x-hidden">
              <Flex className="items-center pb-3 lg:pb-6">
                <Media greaterThanOrEqual="xxl">
                  <Text.Heading size="large">{sectionName}</Text.Heading>
                </Media>
                <Media between={["lg", "xxl"]}>
                  <Text.Heading size="medium">{sectionName}</Text.Heading>
                </Media>
                <Media lessThan="lg">
                  <Text.Heading size="small">{sectionName}</Text.Heading>
                </Media>
              </Flex>
              <Block
                alignSelf="center"
                data-testid="Home--category-scroller"
                marginX={{ _: "-16px", sm: "0px" }}
              >
                <Media lessThan="sm">
                  <Carousel
                    centeredSlides
                    centeredSlidesBounds
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    className="category_scroller_mobile"
                    enableArrowControls={false}
                    enableFreeScroll
                    enableMousewheel
                    id={`category-scroller-${tag}-mobile`}
                    renderSlide={({ collection }) => collection}
                    responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
                    showScrollbar={false}
                    slides={slides}
                    slidesOffsetAfter={16}
                    slidesOffsetBefore={16}
                    variant="card"
                  />
                </Media>
                <Media greaterThanOrEqual="sm">
                  <Carousel
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    className="category_scroller"
                    enableArrowControls
                    enableFreeScroll
                    enableMousewheel
                    id={`category-scroller-${tag}`}
                    renderSlide={({ collection }) => collection}
                    responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
                    showScrollbar={false}
                    slides={slides}
                    variant="card"
                  />
                </Media>
              </Block>
            </Container>
          </Grid.Item>
        )
      })}
    </StyledGridHScrollContainer>
  )
}
