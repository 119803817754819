/**
 * @generated SignedSource<<f8596e87b79d6caea89d14bc1f08a272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryFeaturedCarouselSlide_data$data = {
  readonly collection: {
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"Banner_data" | "CategorySpotlightBanner_data" | "PrimaryDropProviderMintProgress_data" | "useDropState_data">;
  };
  readonly overrideUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderCollectionItemCategory_data">;
  readonly " $fragmentType": "CategoryFeaturedCarouselSlide_data";
};
export type CategoryFeaturedCarouselSlide_data$key = {
  readonly " $data"?: CategoryFeaturedCarouselSlide_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryFeaturedCarouselSlide_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryFeaturedCarouselSlide_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderCollectionItemCategory_data"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overrideUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Banner_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CategorySpotlightBanner_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PrimaryDropProviderMintProgress_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useDropState_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CategoryPageCarouselType",
  "abstractKey": null
};

(node as any).hash = "8b3c2d9ca11a48d35c9f66fcf35101cc";

export default node;
