import React from "react"
import { Media, Text, Container, Skeleton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { AccountOrCollectionInfo } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionInfo"
import { HeroBanner } from "@/components/layout/AccountOrCollectionPage/components/HeroBanner"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { CategoryHeader_data$key } from "@/lib/graphql/__generated__/CategoryHeader_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

type Props = {
  data: CategoryHeader_data$key | null
}

export const CategoryHeader = ({ data: dataKey }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment CategoryHeader_data on CollectionType {
        name
        bannerImageUrl
        description
      }
    `,
    dataKey,
  )

  const renderPlaceHolder = () => {
    return (
      <DivContainer>
        <StyledBlock>
          <HeroBanner altText="Solana" height={220} />
        </StyledBlock>
        <Skeleton className="mb-2 mt-6 items-start px-5 py-0 xl:px-8 xxl:px-16">
          <Skeleton.Row>
            <Skeleton.Line className="h-12 w-[200px]" />
          </Skeleton.Row>
          <Skeleton.Row>
            <Skeleton.Line className="h-6 w-[360px]" />
          </Skeleton.Row>
          <Skeleton.Row>
            <Skeleton.Line className="h-6 w-[360px]" />
          </Skeleton.Row>
          <Skeleton.Row className="mb-7">
            <Skeleton.Line className="h-6 w-[360px]" />
          </Skeleton.Row>
        </Skeleton>
      </DivContainer>
    )
  }

  if (!data) {
    return renderPlaceHolder()
  }
  const { bannerImageUrl, name } = data
  const descriptionText =
    data.description ||
    t(
      "collections.category.description",
      "Welcome to the home of {{name}} on OpenSea. Discover the best items in this collection.",
      { name: data.name },
    )
  return (
    <DivContainer>
      <StyledBlock>
        <HeroBanner altText={name} bannerImageUrl={bannerImageUrl} />
      </StyledBlock>
      <Container className="mb-2 flex flex-col items-start">
        <Media lessThan="sm">
          <Text.Display asChild className="mt-8" size="medium">
            <div>
              {t("collections.category.mobileTitle", "Explore {{name}}", {
                name,
              })}
            </div>
          </Text.Display>
        </Media>
        <Media greaterThanOrEqual="sm">
          <Text.Display asChild className="pb-4 pt-8 text-left" size="large">
            <h1>
              {t("collections.category.title", "Explore {{name}}", { name })}
            </h1>
          </Text.Display>
        </Media>
        <AccountOrCollectionInfo
          desktopLines={3}
          mobileLines={3}
          text={descriptionText}
        />
      </Container>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  position: relative;
`
const StyledBlock = styled(Block)`
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};
`
