/**
 * @generated SignedSource<<04b4226df7a472acd94f452d061960f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TrendingCategoryTagShelvesSectionQuery$variables = {
  categorySlug: string;
};
export type TrendingCategoryTagShelvesSectionQuery$data = {
  readonly trendingTagsByCategory: ReadonlyArray<{
    readonly slug: string;
  }> | null;
};
export type TrendingCategoryTagShelvesSectionQuery = {
  response: TrendingCategoryTagShelvesSectionQuery$data;
  variables: TrendingCategoryTagShelvesSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categorySlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "categorySlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrendingCategoryTagShelvesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TagType",
        "kind": "LinkedField",
        "name": "trendingTagsByCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrendingCategoryTagShelvesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TagType",
        "kind": "LinkedField",
        "name": "trendingTagsByCategory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "414487c3373a0e37e2e8e5a99169f95f",
    "id": null,
    "metadata": {},
    "name": "TrendingCategoryTagShelvesSectionQuery",
    "operationKind": "query",
    "text": "query TrendingCategoryTagShelvesSectionQuery(\n  $categorySlug: CategoryV2Slug!\n) {\n  trendingTagsByCategory(category: $categorySlug) {\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0d56223bf304e7b00c9d40a965a8323";

export default node;
