import React from "react"
import { Media, Text, Container, Flex } from "@opensea/ui-kit"
import { capitalize } from "lodash"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCollectionCard } from "@/features/home/components/HomePageShelf/HomePageCollectionCard.react"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import { TopCategoryBuysSectionQuery } from "@/lib/graphql/__generated__/TopCategoryBuysSectionQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { filter } from "@/lib/helpers/array"
import { useCategoryNameGetter } from "./collection-edit/categoryUtils"
import { CategoryScrollerSkeleton } from "./TrendingCategory/CategoryScrollerSkeleton.react"
import { useNotableActivityCollectionsPageSize } from "./useNotableActivityCollectionsPageSize"
import { useTrackNotableActivityCollectionsLoaded } from "./useTrackNotableActivityCollectionsLoaded"

type Props = {
  category: string
}

export const TopCategoryBuysSection = ({ category }: Props) => {
  const t = useTranslate("categorization")
  const categoryNameGetter = useCategoryNameGetter()
  const pageSize = useNotableActivityCollectionsPageSize()

  const [data] = useNoSuspenseLazyLoadQuery<TopCategoryBuysSectionQuery>(
    graphql`
      query TopCategoryBuysSectionQuery(
        $category: CategoryV2Slug!
        $pageSize: Int!
      ) {
        notableActivityCollections(
          pageSize: $pageSize
          first: 12
          category: $category
        ) {
          edges {
            node {
              id
              ...HomePageCollectionCard_data @arguments(timeWindow: ONE_DAY)
            }
          }
        }
      }
    `,
    { category, pageSize },
  )

  useTrackNotableActivityCollectionsLoaded({
    category,
    collections: data,
  })

  if (!data) {
    return (
      <Grid.Item xs={12}>
        <Container>
          <CategoryScrollerSkeleton />
        </Container>
      </Grid.Item>
    )
  }

  const collections = filter(
    data.notableActivityCollections.edges.map(edge => edge?.node),
    collection => collection,
  )

  const eventSource = `TopCollectorBuysTodayIn${capitalize(category)}`

  const sectionName = t(
    "category.topBuys.header",
    "Top Collector Buys Today in {{category}}",
    {
      category: categoryNameGetter(category),
    },
  )

  if (
    !data.notableActivityCollections.edges.length ||
    data.notableActivityCollections.edges.length < 6
  ) {
    return null
  }

  const slides = collections.map((collection, idx) => {
    return {
      id: `${collection.id}`,
      collection: (
        <HomePageCollectionCard
          collection={collection}
          eventParams={{
            idx,
            category,
          }}
          eventSource={eventSource}
          volumeWindow="ONE_DAY"
        />
      ),
    }
  })

  return (
    <Grid.Item xs={12}>
      {/* Overflow hidden used for Carousel "card" variants that require a visible overflow */}
      <Container className="overflow-x-hidden">
        <Flex className="items-center pb-3 lg:pb-6">
          <Media greaterThanOrEqual="xxl">
            <Text.Heading size="large">{sectionName}</Text.Heading>
          </Media>
          <Media between={["lg", "xxl"]}>
            <Text.Heading size="medium">{sectionName}</Text.Heading>
          </Media>
          <Media lessThan="lg">
            <Text.Heading size="small">{sectionName}</Text.Heading>
          </Media>
        </Flex>

        <Block
          alignSelf="center"
          data-testid="Home--category-scroller"
          marginX={{ _: "-16px", sm: "0px" }}
        >
          <Media lessThan="sm">
            <Carousel
              centeredSlides
              centeredSlidesBounds
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={`${category}_category_top_buys_scroller_mobile`}
              enableArrowControls={false}
              enableFreeScroll
              enableMousewheel
              id={`category-top-buys-scroller-${category}-mobile`}
              key={category}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              variant="card"
            />
          </Media>
          <Media greaterThanOrEqual="sm">
            <Carousel
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={`${category}_category_top_buys_scroller`}
              enableArrowControls
              enableFreeScroll
              enableMousewheel
              id={`category-top-buys-scroller-${category}`}
              key={category}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              variant="card"
            />
          </Media>
        </Block>
      </Container>
    </Grid.Item>
  )
}
