import { useVariant } from "@/hooks/useVariant"

const DEFAULT_PAGE_SIZE = 150

export const useNotableActivityCollectionsPageSize = () => {
  const { enabled, payload } = useVariant(
    "notable_activity_collections_page_size",
  )

  if (!enabled || Number.isNaN(Number(payload.value))) {
    return DEFAULT_PAGE_SIZE
  }

  return Number(payload.value)
}
