/**
 * @generated SignedSource<<a3945af7d00df9eaf18132376fa29e0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryCollectionsList_data$data = {
  readonly collections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly banner: string | null;
        readonly logo: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"CollectionsList_data">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "CategoryCollectionsList_data";
};
export type CategoryCollectionsList_data$key = {
  readonly " $data"?: CategoryCollectionsList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryCollectionsList_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "collections"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "parents"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CategoryCollectionsListQuery.graphql')
    }
  },
  "name": "CategoryCollectionsList_data",
  "selections": [
    {
      "alias": "collections",
      "args": [
        {
          "kind": "Variable",
          "name": "parents",
          "variableName": "parents"
        },
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": "CREATED_DATE"
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "__CategoryCollectionsList_collections_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "showContextMenu",
                      "value": false
                    },
                    {
                      "kind": "Literal",
                      "name": "showStats",
                      "value": false
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "CollectionsList_data"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "banner",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f006a8e0bc1cd6ee7b0d21df243f104d";

export default node;
