import React, { ReactNode } from "react"
import { useFragment } from "react-relay"
import { graphql, _FragmentRefs } from "relay-runtime"
import { Button } from "@/design-system/Button"
import { ButtonGroup } from "@/design-system/ButtonGroup"
import { Tooltip } from "@/design-system/Tooltip"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionEditToolbar_data$key } from "@/lib/graphql/__generated__/CollectionEditToolbar_data.graphql"
import {
  getCollectionAssetCreateUrl,
  getCollectionEditUrl,
  getCollectionRoyaltiesUrl,
} from "@/lib/helpers/collection"
import { useIsEditable } from "../hooks/useIsEditable"

type Props = {
  children: ({ controls }: { controls: ReactNode }) => JSX.Element
  collection: CollectionEditToolbar_data$key
}

export const CollectionEditToolbar = ({
  children,
  collection: collectionKey,
}: Props) => {
  const t = useTranslate("collections")
  const collection = useFragment(
    graphql`
      fragment CollectionEditToolbar_data on CollectionType {
        slug
        representativeAsset {
          assetContract {
            openseaVersion
          }
        }
        ...collection_url
      }
    `,
    collectionKey,
  )

  const { slug } = collection

  // TODO: We need to figure out a better way to determine this in BE. defaultMintableAssetContract is faulty
  const isMintable = Boolean(
    collection.representativeAsset
      ? collection.representativeAsset.assetContract.openseaVersion
      : true,
  )
  const isSSFEnabled = useIsSSFEnabled()
  const isEditable = useIsEditable({ slug })

  if (!isEditable) {
    return null
  }

  return children({
    controls: (
      <>
        <ButtonGroup marginRight="20px" variant="secondary">
          <Tooltip content={t("toolbar.edit", "Edit collection")}>
            <ButtonGroup.Button
              aria-label={t("toolbar.editLabel", "Edit collection")}
              href={getCollectionEditUrl(collection)}
              icon="edit"
            />
          </Tooltip>
          <Tooltip content={t("toolbar.earnings", "View earnings")}>
            <ButtonGroup.Button
              aria-label={t("toolbar.earningsLabel", "View earnings")}
              href={getCollectionRoyaltiesUrl(collection)}
              icon="view_list"
            />
          </Tooltip>
        </ButtonGroup>
        {isSSFEnabled && (
          <Tooltip
            content={t(
              "toolbar.notCreatedOnOSMessage",
              "Your collection was not created on OpenSea",
            )}
            disabled={isMintable}
          >
            <span>
              <Button
                disabled={!isMintable}
                href={getCollectionAssetCreateUrl(collection)}
              >
                {t("toolbar.addItemCTA", "Add item")}
              </Button>
            </span>
          </Tooltip>
        )}
      </>
    ),
  })
}
