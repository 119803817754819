import React from "react"
import { Container } from "@opensea/ui-kit"
import { times } from "lodash"
import { graphql, useLazyLoadQuery } from "react-relay"
import { Grid } from "@/design-system/Grid/Grid.react"
import { TrendingCategoryTagShelvesSectionQuery } from "@/lib/graphql/__generated__/TrendingCategoryTagShelvesSectionQuery.graphql"
import { useCategoryNameGetter } from "../collection-edit/categoryUtils"
import { CategoryScrollerSkeleton } from "./CategoryScrollerSkeleton.react"
import { TrendingCategoryList } from "./TrendingCategoryList.react"
import { TrendingTagList } from "./TrendingTagList.react"

type Props = {
  category: string
}

export const TrendingCategoryTagShelvesSectionSkeleton = () => {
  return (
    <>
      {times(3, i => (
        <Grid.Item key={i} marginBottom="24px">
          <Container>
            <CategoryScrollerSkeleton />
          </Container>
        </Grid.Item>
      ))}
    </>
  )
}

export const TrendingCategoryTagShelvesSection = ({ category }: Props) => {
  const categoryNameGetter = useCategoryNameGetter()
  const data = useLazyLoadQuery<TrendingCategoryTagShelvesSectionQuery>(
    graphql`
      query TrendingCategoryTagShelvesSectionQuery(
        $categorySlug: CategoryV2Slug!
      ) {
        trendingTagsByCategory(category: $categorySlug) {
          slug
        }
      }
    `,
    { categorySlug: category },
  )

  if (!data.trendingTagsByCategory) {
    return <TrendingCategoryTagShelvesSectionSkeleton />
  }

  if (category && data.trendingTagsByCategory.length) {
    return (
      <Grid.Item xs={12}>
        <TrendingTagList
          category={category}
          tags={data.trendingTagsByCategory.map(tag => tag.slug)}
        />
      </Grid.Item>
    )
  }

  // Hide music trending category because we don't have trending lists for subcategories
  // like "Adventure games" for gaming, etc. so trending is redundant with the Trending chart.
  if (category && category != "music") {
    return (
      <Grid.Item xs={12}>
        <TrendingCategoryList
          categories={[{ slug: category, name: categoryNameGetter(category) }]}
        />
      </Grid.Item>
    )
  }

  return null
}
