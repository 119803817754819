/**
 * @generated SignedSource<<869838ebdfa898904c4fa3468a4e893a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrendingTagListQuery$variables = {
  categories: ReadonlyArray<string>;
  tags: ReadonlyArray<string>;
};
export type TrendingTagListQuery$data = {
  readonly trendingCollectionsByCategoryTagBatched: ReadonlyArray<{
    readonly collection: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
    } | null;
    readonly tag: {
      readonly slug: string;
    } | null;
  } | null> | null;
};
export type TrendingTagListQuery = {
  response: TrendingTagListQuery$data;
  variables: TrendingTagListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tags"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories"
  },
  {
    "kind": "Variable",
    "name": "tags",
    "variableName": "tags"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrendingTagListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TagCollectionMapping",
        "kind": "LinkedField",
        "name": "trendingCollectionsByCategoryTagBatched",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "timeWindow",
                    "value": "SEVEN_DAY"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "HomePageCollectionCard_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TagType",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrendingTagListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TagCollectionMapping",
        "kind": "LinkedField",
        "name": "trendingCollectionsByCategoryTagBatched",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "banner",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verificationStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionStatsV2Type",
                "kind": "LinkedField",
                "name": "statsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalSupply",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "dropv2",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "stages",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTime",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mintedItemCount",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "Drop721LimitedEditionType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "Drop1155LimitedEditionType",
                    "abstractKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "statsTimeWindow",
                    "value": "SEVEN_DAY"
                  }
                ],
                "concreteType": "WindowCollectionStatsType",
                "kind": "LinkedField",
                "name": "windowCollectionStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "floorPrice",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "volume",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "windowCollectionStats(statsTimeWindow:\"SEVEN_DAY\")"
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCategory",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TagType",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "133ad1d2bafecbe3dee26e71ede1eb0c",
    "id": null,
    "metadata": {},
    "name": "TrendingTagListQuery",
    "operationKind": "query",
    "text": "query TrendingTagListQuery(\n  $categories: [CategoryV2Slug!]!\n  $tags: [TagSlug!]!\n) {\n  trendingCollectionsByCategoryTagBatched(categories: $categories, tags: $tags) {\n    collection {\n      id\n      ...HomePageCollectionCard_data_4ieMhw\n    }\n    tag {\n      slug\n      id\n    }\n  }\n}\n\nfragment HomePageCollectionCardFooter_data_4ieMhw on CollectionType {\n  windowCollectionStats(statsTimeWindow: SEVEN_DAY) {\n    floorPrice {\n      unit\n      symbol\n    }\n    volume {\n      unit\n      symbol\n    }\n  }\n}\n\nfragment HomePageCollectionCard_data_4ieMhw on CollectionType {\n  banner\n  name\n  verificationStatus\n  ...useDropState_data\n  ...HomePageCollectionCardFooter_data_4ieMhw\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useDropState_data on CollectionType {\n  statsV2 {\n    totalSupply\n  }\n  dropv2 {\n    __typename\n    stages {\n      __typename\n      startTime\n      endTime\n      id\n    }\n    mintedItemCount\n    ... on Drop721LimitedEditionType {\n      totalItemCount\n    }\n    ... on Drop1155LimitedEditionType {\n      totalItemCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4e02632368e9f0bf713a6ce72dc6f18";

export default node;
