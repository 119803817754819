/**
 * @generated SignedSource<<003dc0b811da88f011e387b47b4bdc22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryHeader_data$data = {
  readonly bannerImageUrl: string | null;
  readonly description: string | null;
  readonly name: string;
  readonly " $fragmentType": "CategoryHeader_data";
};
export type CategoryHeader_data$key = {
  readonly " $data"?: CategoryHeader_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryHeader_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryHeader_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "26b3ef8d3e671e500519e976222e136e";

export default node;
