import { find } from "lodash"
import { VISIBLE_CATEGORIES } from "@/features/categorization/collection-edit/categoryUtils"
import { categoriesCollectionQuery } from "@/lib/graphql/__generated__/categoriesCollectionQuery.graphql"
import { categoriesQuery } from "@/lib/graphql/__generated__/categoriesQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const doesCollectionExist = async (slug: string): Promise<boolean> => {
  try {
    const [data] = await fetch<categoriesCollectionQuery>(
      graphql`
        query categoriesCollectionQuery($collection: CollectionSlug!) {
          collection(collection: $collection) {
            id
          }
        }
      `,
      { collection: slug },
    )

    return !!data.collection?.id
  } catch (e) {
    // assume it doesn't exist and let the page fail semi-gracefully

    return false
  }
}

export const isCategory = async (slug: string): Promise<boolean> => {
  const [data] = await fetch<categoriesQuery>(
    graphql`
      query categoriesQuery {
        categories {
          slug
        }
      }
    `,
    {},
  )
  return data.categories.some(c => c.slug === slug)
}

export const isMaverickCategory = (slug: string) => {
  return !!find(VISIBLE_CATEGORIES, { slug })
}
