/**
 * @generated SignedSource<<16b038cdc87f670a96de7aaa98e7a0a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CategorySpotlightBanner_data$data = {
  readonly assetCount: number | null;
  readonly bannerImageUrl: string | null;
  readonly logo: string | null;
  readonly metadata: {
    readonly hero: {
      readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropHeroMedia_data">;
    } | null;
  } | null;
  readonly name: string;
  readonly owner: {
    readonly config: Config | null;
    readonly displayName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly statsV2: {
    readonly floorPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  };
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "CategorySpotlightBanner_data";
};
export type CategorySpotlightBanner_data$key = {
  readonly " $data"?: CategorySpotlightBanner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySpotlightBanner_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategorySpotlightBanner_data",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assetCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "config",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLink_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "floorPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionMetadataType",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroMetadataType",
          "kind": "LinkedField",
          "name": "hero",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PrimaryDropHeroMedia_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "44b3371d202bc3c4d4956a0627b64a26";

export default node;
