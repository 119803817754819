/**
 * @generated SignedSource<<435ab7f0734ccb1e35fdd4223df127b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type categoriesQuery$variables = {};
export type categoriesQuery$data = {
  readonly categories: ReadonlyArray<{
    readonly slug: string;
  }>;
};
export type categoriesQuery = {
  response: categoriesQuery$data;
  variables: categoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CategoryType",
    "kind": "LinkedField",
    "name": "categories",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "categoriesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "categoriesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f975f63cf7fb7d3236706d0862dad064",
    "id": null,
    "metadata": {},
    "name": "categoriesQuery",
    "operationKind": "query",
    "text": "query categoriesQuery {\n  categories {\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f86166cc3fcb171d7e1b06cf32f85bc";

export default node;
