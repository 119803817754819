import React from "react"
import {
  breakpoints,
  Media,
  useIsLessThanXXXl,
  useIsLessThanLg,
  useIsLessThanMd,
  useIsLessThanSm,
  Container,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { Carousel } from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { Skeleton } from "@opensea/ui-kit"
import { CategoryPagePageHeaderCollectionItem } from "@/features/home/components/HeaderCollectionItem.react"
import { HomePageCarouselSkeleton } from "@/features/home/components/HomePageCarouselSkeleton.react"
import { responsiveConfig as homePageCollectionItemResponsiveConfig } from "@/features/home/components/HomePageHeader/HomePageHeaderCarousel.react"
import { Banner } from "@/features/primary-drops/components/Banner"
import { PrimaryDropProvider } from "@/features/primary-drops/containers/PrimaryDropProvider"
import {
  DROP_STATES,
  DROP_STATES_TYPE,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { useIsMounted } from "@/hooks/useIsMounted"
import { CategoryFeaturedCarousel_data$key } from "@/lib/graphql/__generated__/CategoryFeaturedCarousel_data.graphql"
import { CategoryFeaturedCarouselSlide_data$key } from "@/lib/graphql/__generated__/CategoryFeaturedCarouselSlide_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { resizeImage } from "@/lib/helpers/urls"
import { media } from "@/styles/styleUtils"
import { CategorySpotlightBanner } from "./CategorySpotlightBanner"

type Props = {
  data: CategoryFeaturedCarousel_data$key | null
  handleSlideIndexChange?: (str: string) => void
  id: string
  shouldUseMultiCarousel?: boolean
}

const responsiveConfig = {
  default: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
  xs: {
    slidesPerView: 1.25,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
}

export const CategoryFeaturedCarousel = ({
  handleSlideIndexChange,
  id,
  data: dataKey,
  shouldUseMultiCarousel = false,
}: Props) => {
  const isMounted = useIsMounted()
  const isXXXL = useIsLessThanXXXl()
  const isLarge = useIsLessThanLg()
  const isMedium = useIsLessThanMd()
  const isSmall = useIsLessThanSm()

  const data = useFragment(
    graphql`
      fragment CategoryFeaturedCarousel_data on Query
      @argumentDefinitions(category: { type: "CategoryV2Slug!" }) {
        categoryPageCarousel(category: $category) {
          ...CategoryFeaturedCarouselSlide_data
          collection {
            name
            logo
            bannerImageUrl
            ...CategorySpotlightBanner_data
          }
          overrideUrl
          ...HeaderCollectionItemCategory_data
        }
      }
    `,
    dataKey,
  )

  if (!data || !data.categoryPageCarousel.length || !isMounted) {
    if (shouldUseMultiCarousel) {
      return (
        <Grid.Item className="relative z-[1]" marginBottom="40px" xs={12}>
          <HomePageCarouselSkeleton />
        </Grid.Item>
      )
    }

    return (
      <Grid.Item marginBottom="40px" xs={12}>
        <StyledContainer>
          <Skeleton>
            <StyledSkeletonSection />
          </Skeleton>
        </StyledContainer>
      </Grid.Item>
    )
  }

  let priorityImagesCount = 1
  if (isXXXL) {
    priorityImagesCount = 6
  } else if (isLarge) {
    priorityImagesCount = 4
  } else if (isMedium) {
    priorityImagesCount = 3
  } else if (isSmall) {
    priorityImagesCount = 2
  }

  const onlyLogoOrOverrideUrl = data.categoryPageCarousel.filter(
    entry => entry.overrideUrl || entry.collection.logo,
  )

  const slides = onlyLogoOrOverrideUrl.map((entry, index) => {
    return {
      id: `${entry.collection.name}${index}`,
      collection: (
        <Slide
          dataKey={data.categoryPageCarousel[index]}
          index={index}
          priorityImagesCount={priorityImagesCount}
          shouldUseMultiCarousel={shouldUseMultiCarousel}
        />
      ),
    }
  })

  const handleActiveIndexChange = (index: number): void => {
    if (shouldUseMultiCarousel) {
      return
    }

    const data = slides[index].collection.props
    let imgUrl = ""
    if (data?.overrideUrl) {
      imgUrl = data?.overrideUrl
    } else {
      imgUrl = data?.dataKey.bannerImageUrl || ""
    }

    if (handleSlideIndexChange) {
      handleSlideIndexChange(
        resizeImage(imgUrl, { size: 500, freezeAnimation: true }),
      )
    }
  }

  return (
    <Grid.Item xs={12}>
      <Block marginX={{ _: "-34px", sm: "0px" }}>
        <Media lessThan="sm">
          <StyledCarousel
            centeredSlides
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="category_page_header_carousel_mobile"
            enableArrowControls={false}
            enableFreeScroll
            enableMousewheel
            handleActiveIndexChange={handleActiveIndexChange}
            id={`category-page-header-${id}-mobile`}
            key={String(shouldUseMultiCarousel)}
            renderSlide={({ collection }) => collection}
            responsiveConfig={responsiveConfig}
            showScrollbar={false}
            slides={slides}
            variant="spotlight"
          />
        </Media>
        <Media greaterThanOrEqual="sm">
          <StyledCarousel
            autoplayConfig={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="category_page_header_carousel"
            enableArrowControls
            enableFreeScroll
            enableMousewheel
            handleActiveIndexChange={handleActiveIndexChange}
            id={`category-page-header-${id}`}
            key={String(shouldUseMultiCarousel)}
            loop={shouldUseMultiCarousel ? false : slides.length > 1}
            renderSlide={({ collection }) => collection}
            responsiveConfig={
              shouldUseMultiCarousel
                ? homePageCollectionItemResponsiveConfig
                : responsiveConfig
            }
            showScrollbar={false}
            slides={slides}
            variant="spotlight"
          />
        </Media>
      </Block>
    </Grid.Item>
  )
}
const Slide = ({
  dataKey,
  index,
  shouldUseMultiCarousel,
  priorityImagesCount,
}: {
  dataKey: CategoryFeaturedCarouselSlide_data$key
  index: number
  shouldUseMultiCarousel: boolean
  priorityImagesCount: number
}) => {
  const data = useFragment(
    graphql`
      fragment CategoryFeaturedCarouselSlide_data on CategoryPageCarouselType {
        ...HeaderCollectionItemCategory_data
        overrideUrl
        collection {
          slug
          name
          ...Banner_data
          ...CategorySpotlightBanner_data
          ...PrimaryDropProviderMintProgress_data
          ...useDropState_data
        }
      }
    `,
    dataKey,
  )
  const dropState = useDropState(data.collection)
  const treatAsDrop = (
    [DROP_STATES.UPCOMING, DROP_STATES.ACTIVE] as (DROP_STATES_TYPE | null)[]
  ).includes(dropState)
  if (shouldUseMultiCarousel) {
    return (
      <CategoryPagePageHeaderCollectionItem
        dataKey={data}
        index={index}
        isPriorityImage={index < priorityImagesCount}
        size={800}
      />
    )
  } else if (treatAsDrop) {
    return (
      <PrimaryDropProvider
        collectionSlug={data.collection.slug}
        dataKey={data.collection}
      >
        <Banner
          dataKey={data.collection}
          disableZoomOnHover
          eventSource="CategoryPageBanner"
        />
      </PrimaryDropProvider>
    )
  } else {
    return (
      <CategorySpotlightBanner
        dataKey={data.collection}
        eventParams={{
          collectionName: data.collection.name,
          index,
        }}
        eventSource="CategoryPageSpotlightBannerClick"
        overrideUrl={data.overrideUrl}
      />
    )
  }
}
const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
  padding: 0;
`

const StyledCarousel = styled(Carousel)`
  border-radius: ${props => props.theme.borderRadius.large};

  @media (max-width: ${breakpoints.sm}px) {
    border-radius: ${props => props.theme.borderRadius.large};
  }
`

const StyledSkeletonSection = styled(Skeleton.Block)`
  width: 100%;

  ${media({
    xs: css`
      border-radius: ${props => props.theme.borderRadius.large};
      /* ratio 7/8 (8/7 = 1.14) */
      height: calc(1.14 * (100vw - 32px));
    `,
    md: css`
      /* ratio 4/3 (3/4 = 0.75) */
      height: calc(0.75 * (100vw - 32px));
    `,
    lg: css`
      /* ratio 5/2 (2/5 = 0.4) */
      height: calc(0.4 * (100vw - 64px));
    `,
    xl: css`
      border-radius: ${props => props.theme.borderRadius.large};
    `,
    xxl: css`
      /* ratio 18/5 (5/18 = 0.278) */
      height: calc(0.278 * (100vw - 128px));
    `,
  })}
`
