import React from "react"
import { usePaginationFragment, graphql } from "react-relay"
import { CategoryCollectionsList_data$key } from "@/lib/graphql/__generated__/CategoryCollectionsList_data.graphql"
import { CategoryCollectionsListQuery } from "@/lib/graphql/__generated__/CategoryCollectionsListQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { CollectionsList } from "./CollectionsList.react"
import { getShowCollectionCard } from "./GlobalCollectionCard/GlobalCollectionCard.react"

type Props = {
  dataKey: CategoryCollectionsList_data$key | null
}

export const CategoryCollectionsList = ({ dataKey }: Props) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    CategoryCollectionsListQuery,
    CategoryCollectionsList_data$key
  >(
    graphql`
      fragment CategoryCollectionsList_data on Query
      @argumentDefinitions(
        parents: { type: "[CollectionSlug!]" }
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 12 }
      )
      @refetchable(queryName: "CategoryCollectionsListQuery") {
        collections(
          parents: $parents
          after: $cursor
          first: $count
          sortBy: CREATED_DATE
        ) @connection(key: "CategoryCollectionsList_collections") {
          edges {
            node {
              ...CollectionsList_data
                @arguments(showContextMenu: false, showStats: false)
              logo
              banner
            }
          }
        }
      }
    `,
    dataKey,
  )

  return (
    <CollectionsList
      collections={
        data
          ? getNodes(data.collections).filter(collection =>
              getShowCollectionCard(collection.logo, collection.banner),
            )
          : undefined
      }
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
    />
  )
}
