import React, { useState } from "react"
import {
  Media,
  Text,
  Container,
  FlexEnd,
  FlexColumn,
  FlexColumnProps,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { find } from "lodash"
import { useUpdateEffect } from "react-use"
import styled from "styled-components"
import { getAppInitialProps } from "@/components/app/initialProps"
import { CategoryCollectionsList } from "@/components/collections/CategoryCollectionsList.react"
import { CategoryHeader } from "@/components/collections/CategoryHeader.react"
import { TrendingCollectionsList } from "@/components/collections/TrendingCollectionsList.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { StyledGridHScrollContainer } from "@/components/common/StyledGridHScrollContainer"
import { BrowseCategories } from "@/components/layout/home-page/BrowseCategories.react"
import { BrowseCategoriesError } from "@/components/layout/home-page/BrowseCategoriesError.react"
import { CampaignAnnouncementModal } from "@/components/modals/CampaignAnnouncementModal.react"
import { ErrorPage } from "@/components/pages/ErrorPage"
import { Z_INDEX } from "@/constants/zIndex"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { Flex } from "@/design-system/Flex"
import { Grid } from "@/design-system/Grid/Grid.react"
import { CategoryFeaturedCarousel } from "@/features/categorization/CategoryFeaturedCarousel"
import { VISIBLE_CATEGORIES } from "@/features/categorization/collection-edit/categoryUtils"
import { PromoCategorySection } from "@/features/categorization/PromoCategorySection"
import { TopCategoryBuysSection } from "@/features/categorization/TopCategoryBuysSection"
import {
  TrendingCategoryTagShelvesSection,
  TrendingCategoryTagShelvesSectionSkeleton,
} from "@/features/categorization/TrendingCategory/TrendingCategoryTagShelvesSection"
import { CollectionEditToolbar } from "@/features/collections/components/CollectionEditToolbar.react"
import { CategoryLinks } from "@/features/home/components/HomePageHeader/CategoryLinks.react"
import { HomePageStatsTables } from "@/features/home/components/HomePageStats"
import { HomePageLearnCenterCarousel } from "@/features/home/components/LearnCenter/HomePageLearnCenterCarousel.react"
import { nonInteractiveOverlay } from "@/features/primary-drops/styleUtils"
import { OpenSeaHead } from "@/features/seo"
import { pageTitle } from "@/features/seo/utils"
import { useCategoriesInfo } from "@/hooks/useCategoriesInfo"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackCategoryPage } from "@/lib/analytics/events/pageEvents"
import { CategoryPageQuery } from "@/lib/graphql/__generated__/CategoryPageQuery.graphql"
import { graphql, GraphQLInitialProps } from "@/lib/graphql/graphql"
import type { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import {
  doesCollectionExist,
  isCategory,
  isMaverickCategory,
} from "@/lib/helpers/categories"
import { largeFrozenImage, resizeImage } from "@/lib/helpers/urls"
import QP from "@/lib/qp/qp"
import { captureNoncriticalError } from "@/lib/sentry"
import { $nav_height } from "@/styles/variables"
import { DEFAULT_IMG } from "../../constants"
import { useAnnouncementBanner } from "../announcement-banner/utils/context"

type Props = {
  isCategoryPage: boolean
  isMaverickCategoryPage: boolean
  categorySlug: string
}

export const CategoryPage: GraphQLNextPage<CategoryPageQuery, Props> = ({
  data,
  isCategoryPage,
  isMaverickCategoryPage,
  categorySlug,
}) => {
  const t = useTranslate("category")
  const CATEGORIES = useCategoriesInfo()
  const defaultBlurredUrl =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data?.categoryPageCarousel?.[0]?.overrideUrl ??
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data?.categoryPageCarousel?.[0]?.collection?.bannerImageUrl

  const [blurredBackgroundImg, setBlurredBackgroundImg] = useState<string>(
    defaultBlurredUrl
      ? resizeImage(defaultBlurredUrl, { size: 500, freezeAnimation: true })
      : "",
  )

  // We have two types of category pages:
  // 1. Category page for traditional categories - e.g. "art", "gaming", etc
  // 2. Category page for parent collections - e.g. "yuga-labs", "art-blocks", etc
  // Page is rendered slightly different base on which category page is being rendered
  // Main difference is parent collections will display every subcollection rather than just trending
  const isParentCollection =
    isCategoryPage && !CATEGORIES.some(c => c.slug === categorySlug)
  const collection = data?.collection
  let content = null
  const { spotlightTopOffset } = useAnnouncementBanner()

  useMountEffect(() => {
    trackCategoryPage({ categorySlug })
  })

  useUpdateEffect(() => {
    setBlurredBackgroundImg(
      defaultBlurredUrl
        ? resizeImage(defaultBlurredUrl, { size: 500, freezeAnimation: true })
        : "",
    )
  }, [defaultBlurredUrl])

  if (!isCategoryPage && !isMaverickCategoryPage) {
    return <ErrorPage statusCode={404} />
  }

  const maverickCategory = find(VISIBLE_CATEGORIES, { slug: categorySlug })

  const shouldUseMultiCarousel = maverickCategory?.slug === "memberships"

  const shouldShowNotableBuys =
    maverickCategory?.slug === "art" || maverickCategory?.slug === "pfps"

  if (isParentCollection && !isMaverickCategoryPage) {
    content = (
      <Container className="mt-4">
        <CategoryCollectionsList dataKey={data} />
      </Container>
    )
  } else {
    content = isMaverickCategoryPage ? (
      <>
        <StyledGridHScrollContainer marginBottom={40}>
          <Grid.Item xs={12}>
            <Media greaterThanOrEqual="xxl">
              <SpotlightContainer $topOffset={spotlightTopOffset}>
                <BackgroundImage $imgUrl={blurredBackgroundImg} />
                <Grid.Item xs={12}>
                  <CategoryLinks color="white" />
                </Grid.Item>
                <CategoryFeaturedCarousel
                  data={data ?? null}
                  handleSlideIndexChange={(imgSrc: string) =>
                    setBlurredBackgroundImg(imgSrc)
                  }
                  id="xxl"
                  shouldUseMultiCarousel={shouldUseMultiCarousel}
                />
              </SpotlightContainer>
            </Media>

            <Media between={["sm", "xxl"]}>
              <SpotlightContainer $topOffset={spotlightTopOffset}>
                <BackgroundImage $imgUrl={blurredBackgroundImg} />
                <Grid.Item xs={12}>
                  <CategoryLinks color="white" />
                </Grid.Item>
                <CategoryFeaturedCarousel
                  data={data ?? null}
                  handleSlideIndexChange={(imgSrc: string) =>
                    setBlurredBackgroundImg(imgSrc)
                  }
                  id="md"
                  shouldUseMultiCarousel={shouldUseMultiCarousel}
                />
              </SpotlightContainer>
            </Media>

            <Media lessThan="sm">
              <SpotlightContainer $topOffset={spotlightTopOffset}>
                <BackgroundImage $imgUrl={blurredBackgroundImg} />

                <Grid.Item xs={12}>
                  <CategoryLinks color="white" />
                </Grid.Item>
                <CategoryFeaturedCarousel
                  data={data ?? null}
                  handleSlideIndexChange={(imgSrc: string) =>
                    setBlurredBackgroundImg(imgSrc)
                  }
                  id="sm"
                  shouldUseMultiCarousel={shouldUseMultiCarousel}
                />
              </SpotlightContainer>
            </Media>
          </Grid.Item>

          <Grid.Item xs={12}>
            <HomePageStatsTables category={maverickCategory?.slug} isMaverick />
          </Grid.Item>

          {maverickCategory?.slug && shouldShowNotableBuys ? (
            <Grid.Item xs={12}>
              <TopCategoryBuysSection category={maverickCategory.slug} />
            </Grid.Item>
          ) : (
            <></>
          )}

          {maverickCategory?.slug ? (
            <Grid.Item xs={12}>
              <PromoCategorySection category={maverickCategory.slug} />
            </Grid.Item>
          ) : (
            <></>
          )}

          <>
            {maverickCategory?.slug && (
              <SsrSuspense
                fallback={<TrendingCategoryTagShelvesSectionSkeleton />}
              >
                <Grid.Item xs={12}>
                  <TrendingCategoryTagShelvesSection
                    category={maverickCategory.slug}
                  />
                </Grid.Item>
              </SsrSuspense>
            )}
          </>

          {maverickCategory?.slug ? (
            <Grid.Item xs={12}>
              <HomePageLearnCenterCarousel category={maverickCategory.slug} />
            </Grid.Item>
          ) : (
            <></>
          )}

          <CategoryGridItem xs={12}>
            <ErrorBoundary
              fallback={({ error }) => {
                captureNoncriticalError(error)
                return <BrowseCategoriesError />
              }}
            >
              <BrowseCategories currentCategory={maverickCategory?.slug} />
            </ErrorBoundary>
          </CategoryGridItem>
        </StyledGridHScrollContainer>
      </>
    ) : (
      <Container>
        <Media lessThan="sm">
          <Text.Heading asChild className="mb-6 mt-10 text-left" size="small">
            <h4>{t("trending.title", "Trending collections")}</h4>
          </Text.Heading>
        </Media>
        <Media greaterThanOrEqual="sm">
          <Text.Heading asChild className="mb-6 mt-10 text-left" size="medium">
            <h3>{t("trending.collectionsTitle", "Trending collections")}</h3>
          </Text.Heading>
        </Media>
        <TrendingCollectionsList dataKey={data} />
      </Container>
    )
  }

  return (
    <OpenSeaPage
      hasTransparentNavbar={isMaverickCategoryPage}
      hideFooter={false}
    >
      <OpenSeaHead
        description={collection?.description ?? ""}
        image={
          collection?.bannerImageUrl
            ? largeFrozenImage(collection.bannerImageUrl)
            : collection?.imageUrl ?? DEFAULT_IMG
        }
        // TODO(i18n): Translate part of this?
        title={pageTitle(
          `Explore ${
            isMaverickCategoryPage ? maverickCategory?.name : collection?.name
          } NFTs`,
        )}
      />
      {collection && (
        <CollectionEditToolbar collection={collection}>
          {({ controls }) => (
            <FlexEnd
              className="sticky w-full border-b border-level-2 bg-base-1 px-5 py-2.5"
              style={{
                top: $nav_height,
                zIndex: Z_INDEX.MANAGER_BAR,
              }}
            >
              {controls}
            </FlexEnd>
          )}
        </CollectionEditToolbar>
      )}
      {!maverickCategory?.slug && <CategoryHeader data={collection ?? null} />}
      {content}
      <CampaignAnnouncementModal dataKey={data ?? null} />
    </OpenSeaPage>
  )
}

const query = graphql`
  query CategoryPageQuery(
    $categories: [CategorySlug!]
    $collections: [CollectionSlug!]
    $collection: CollectionSlug!
    $categorySlug: CategoryV2Slug!
    $doesCollectionExist: Boolean!
  ) {
    collection(collection: $collection) @include(if: $doesCollectionExist) {
      ...CollectionEditToolbar_data
      bannerImageUrl
      name
      description
      imageUrl
      ...CategoryHeader_data
    }
    categoryPageCarousel(category: $categorySlug) {
      collection {
        bannerImageUrl
      }
      overrideUrl
    }
    ...TrendingCollectionsList_data @arguments(categories: $categories)
    ...CategoryCollectionsList_data @arguments(parents: $collections)
    ...CategoryFeaturedCarousel_data @arguments(category: $categorySlug)
    ...CampaignAnnouncementModal_data
  }
`

CategoryPage.getInitialProps = QP.nextParser(
  { categorySlug: QP.string },
  async (
    { categorySlug },
    ctx,
  ): Promise<GraphQLInitialProps<CategoryPageQuery, Props>> => {
    const isMaverickCategoryPage = isMaverickCategory(categorySlug)
    const variables = {
      collection: categorySlug,
      collections: [categorySlug],
      categories: [categorySlug],
      // This is a dupe but this is what we will keep after deprecating the old category
      categorySlug,
      // Note (@auster-eth): Hacky way to support both maverick and non-maverick flow by skipping
      // collection query if it doesn't exist, can't find a good way to wrap page relay query
      // to not 404 so we do it here in getInitialProps
      doesCollectionExist: await doesCollectionExist(categorySlug),
    }

    const appInitialProps = await getAppInitialProps(ctx, {
      query,
      variables,
      cachePolicy: {
        maxAge: 60 * 5,
      },
    })

    return {
      ...appInitialProps,
      variables,
      isCategoryPage: await isCategory(categorySlug),
      isMaverickCategoryPage,
      categorySlug,
    }
  },
)

const SpotlightContainer = ({
  $topOffset,
  ...props
}: FlexColumnProps & { $topOffset: number }) => (
  <FlexColumn
    className="relative mb-6 w-full overflow-hidden xs:px-4 sm:px-8 xxl:px-16"
    style={{
      paddingTop: $topOffset,
      marginTop: -$topOffset,
    }}
    {...props}
  />
)

const BackgroundImage = styled(Flex)<{
  $imgUrl: string
}>`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
  background-image: ${props => `url(${props.$imgUrl})`};
  background-size: cover;
  transition: background 0.3s linear;
  width: 100%;

  &:after {
    backdrop-filter: blur(60px);
    background: linear-gradient(
      0deg,
      ${props => props.theme.colors.base1} 5%,
      rgba(0, 0, 0, 0) 60%
    );
    background-color: rgba(0, 0, 0, 0.5);
    ${nonInteractiveOverlay}
  }
`

const CategoryGridItem = styled(Grid.Item)`
  background-position: 50% 50%;
  background-size: cover;
`
